import { InfoSection } from '~/components';
import { Loader } from '@setel/web-ui';
import { useEffect } from 'react';
import { useZendeskTagEffect } from '~/shared/hooks/useZendeskTag';
import { ZendeskTagsName } from '~/store';
import { DealGallery } from './DealGallery';
import { DealHeader } from './DealHeader';
import { DealOutletModal } from './DealOutlets';
import * as DealRedeem from './DealRedeem';
import { DealSummary } from './DealSummary';
import { DealRedemptionCriteria } from './DealRedemptionCriteria';
import { useDealDetails, useRedeemBarVisibility, useMersaCard } from './hooks';
import { MesraStatus } from '~/domain/card';
import { trackUserEvent, sendSpendEvent, useRouter } from '~/shared';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { MixDealBanner } from '../MixDealBanner';

export const DealDetails = () => {
  useZendeskTagEffect(ZendeskTagsName.DEAL_DETAIL);

  const { data, status } = useDealDetails();
  const { query } = useRouter();

  useEffect(() => {
    if (!data) {
      return;
    }
    trackUserEvent(EVENT_TYPES.deal_deal_order_view_deals_detail, {
      deal_display_name: data.voucherBatch.content?.title,
      deal_id: data._id,
      source: query.source,
    });
  }, [data?._id]);

  const mersaCard = useMersaCard();
  const setTargetForRedeemBar = useRedeemBarVisibility();
  const purchase = DealRedeem.usePurchase();

  if (status === 'loading') {
    return (
      <div className="flex justify-center h-screen-2/3">
        <Loader type="spinner-blue" />
      </div>
    );
  }
  if (!data) return null;

  const {
    content: { images, summary, buttonText },
    price,
    leftCount,
    voucherBatch: {
      _id: voucherBatchId,
      outletCount,
      content: { title, description, howToClaim, tac } = {},
      merchant: { name: merchantName } = {},
    },
    redemptionCriteriaInfo,
    isRedemptionCriteriaUnlocked,
  } = data;

  const isSoldOut = leftCount === 0;

  const redeemProps: DealRedeem.DealRedeemProps = {
    ...purchase,
    isSoldOut,
    buttonText,
    price,
    isRedemptionCriteriaUnlocked,
    redemptionCriteria: redemptionCriteriaInfo,
    mersaCardStatus: mersaCard?.status,
  };

  return (
    <div className="md:container md:mx-auto md:pt-10 pb-24">
      <div className="flex-grow flex flex-col md:flex-row">
        <DealHeader title={title ?? '-'} />
        <DealGallery isSoldOut={isSoldOut} images={images} />
        <div className="px-4 pb-32 md:pb-10 md:w-1/2 md:ml-6">
          <div className="flex flex-col">
            {redemptionCriteriaInfo && (
              <DealRedemptionCriteria
                className="sm:mt-4 mb-4 sm:order-last"
                {...redemptionCriteriaInfo}
              />
            )}
            {(mersaCard?.status === MesraStatus.FROZEN ||
              mersaCard?.status === MesraStatus.TEMPORARILY_FROZEN) && (
              <div className="flex-grow items-center w-full bg-error-100 p-3 mb-2 rounded">
                <img src="/assets/icon-error.svg" alt="Error icon" />
                <span className="text-sm text-error-800 pl-2">
                  Oops, your Mesra card needs to be active to redeem. Click on
                  the help button.
                </span>
              </div>
            )}
            <MixDealBanner forMobile />
            <DealSummary
              title={title ?? '-'}
              summary={summary}
              merchantName={merchantName ?? '-'}
            />
          </div>
          <DealRedeem.DealRedeemTopBar
            {...redeemProps}
            image={images[0]}
            title={title ?? '-'}
          />
          <DealRedeem.DealRedeem {...redeemProps} ref={setTargetForRedeemBar} />
          <MixDealBanner />
          {!!description && (
            <InfoSection title="What you get?" info={description} />
          )}
          <DealOutletModal
            voucherBatchId={voucherBatchId}
            className="text-petronas-brand"
            outletCount={outletCount}
          />
          {!!howToClaim?.length && (
            <InfoSection title="How to use" info={howToClaim} />
          )}
          {!!tac?.length && (
            <InfoSection title="Terms and conditions" info={tac} />
          )}
        </div>
        <DealRedeem.DealRedeem {...redeemProps} forMobile />
        <DealRedeem.ConfirmRedeem
          price={price}
          onConfirm={() => {
            purchase.request();
            sendSpendEvent({
              value: price.currentPrice,
              unit: price.unit.toString(),
              itemName: title ?? '-',
            });
          }}
        />
        <DealRedeem.SuccessRedeem />
      </div>
    </div>
  );
};
